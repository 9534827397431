import React from "react";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import Icon from "../../components/custom-widgets/icon";
import tabsData from "../../data/commercial-banking-division-data";
import nameToId from "../../helpers/nameToId";

const CommercialBanker = () => {
  tabsData.sort((a, b) => a.id - b.id);
  const title = "Find a Commercial Banker";
  const description =
    "Find a business banker in your area to learn more about the custom products, services, and solutions WaFd Bank has to offer your business.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-banker-near-me"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-commercial-banker-01-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Find a Commercial Banker"
    }
  ];
  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Find a Commercial Banker</h1>
        <p>
          To learn more about our customized products, services and solutions for your business, contact one of our
          commercial bankers.
        </p>
        {tabsData &&
          tabsData
            .sort((a, b) => a.stateName.localeCompare(b.stateName))
            .map((tab, index) => (
              <div key={tab.eventKey} id={`${nameToId(tab.stateName)}-commercial-bankers`}>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="mt-4 font-weight-bold">{tab.stateName}</h2>
                  </div>
                </div>
                {tab.contactData.map((division, index) => (
                  <div key={index} className="row">
                    <div className="col-md-12">
                      <h3>{division.name}</h3>
                    </div>
                    {division.contact.map((contact, index) => (
                      <div key={index} className="mb-2 col-lg-7 col-xl-6">
                        {contact.phone ? (
                          <div className="d-inline-block">
                            <Icon
                              {...{
                                name: "phone-alt",
                                class: "mr-3 mt-1 mb-1 fa-w-12"
                              }}
                            />
                            <a
                              id={`${nameToId(division.name)}-contact-phone-link-${index + 1}`}
                              href={`tel:${contact.phone}`}
                              className="ml-1 text-decoration-none"
                            >
                              {contact.phone}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-block">
                          <Icon
                            {...{
                              name: "map-marker-alt",
                              class: "mr-4 mt-1"
                            }}
                          />
                          <a
                            id={`${nameToId(division.name)}-maps-link-${index + 1}`}
                            rel="noopener noreferrer"
                            className="d-inline-block align-top text-decoration-none"
                            href={`https://maps.google.com/maps?daddr=${contact.addressLine1}${
                              contact.addressLine2 ? "%2c" + contact.addressLine2 : ""
                            }%2c${contact.addressLine3}`}
                            target="_blank"
                          >
                            {contact.addressLine1}
                            <br />
                            {contact.addressLine2 ? (
                              <>
                                {contact.addressLine2} <br />
                              </>
                            ) : (
                              ""
                            )}
                            {contact.addressLine3}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {index !== tabsData.length - 1 ? <hr /> : null}
              </div>
            ))}
      </section>
      <ContactInfo type="commercial" />
    </SecondaryLanding>
  );
};
export default CommercialBanker;
